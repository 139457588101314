/* You can add global styles to this file, and also import other style files */


body{
  min-height: 100vh;
  margin:0;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  .header{
    min-height:40px;
    background: black;
    color: white;
    padding: 15px;
    .menuIcon {
      width: 40px;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  article {
    padding-top: 100px;
    padding-bottom: 100px ;
  }
  footer{
    min-height:50px;
    background: black;
    margin-top:auto;
    color: white;
    font-size: 13px;
    padding: 15px;
    clear: both;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  h1,h2,h3,h4 {
    font-family: 'Quicksand',sans-serif;
    font-size: 4em;
    text-align: center;
    font-weight: normal;
    margin: 10px 0 0 0;
  }
  h2 {
    font-size: 2em;
    margin-top: 10px;
    margin-bottom: 100px;

  }
  h3 {
    font-size: 1em;
    margin-top: 10px;

  }
  p {
    text-align: center;
  }
  .indexImg {
    width: 100%;
    margin-top: 100px;
    max-width: 500px;
  }
  .emailIcon {
    width: 20px;
    margin-bottom: -4px;
  }
}

